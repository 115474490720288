.call {
	text-align: center;
	background-color: #f19b3e;
	box-sizing: content-box;
	padding: 0px;
	margin: 0px;
	position: absolute;
	z-index: 11;
	top: 0px;
	left: 0px;
	height: 100vh;
	width: 100vw;
	overflow: hidden;
	// max-width: 25rem;
	// margin: auto;
	.call_screen {
		padding: 1vh 1px;
		display: grid;
		grid-template-rows: 80vh 20vh;
		box-sizing: border-box;
		height: 100vh;
		width: 100vw;
		color: #ffffff;
		position: relative;
		.call_info {
			.call_type {
				p {
					font-weight: 700;
					font-size: 18px;
					line-height: 25px;
					margin: 1em 0;
					text-align: center;
					color: #ffffff;
				}
			}
			.contact {
				p {
					font-weight: 600;
					font-size: 20px;
					line-height: 27px;
					text-align: center;
					color: #ffecec;
				}
				.avatar {
					position: relative;
					height: 8rem;
					width: 8rem;
					display: grid;
					background: rgb(239 239 239 / 87%);
					border-radius: 7rem;
					margin: 1.5rem auto;
					background: linear-gradient(
						180deg,
						rgba(252, 252, 252, 0.2) 0%,
						rgb(252 252 252 / 0%) 100%
					);
					.graphic {
						width: 7rem;
						transform: translate(-50%, -50%);
						top: 52%;
						left: 50%;
						position: absolute;
					}
				}
			}
			.call_duration {
				.label {
					font-size: 0.938rem;
					font-weight: 600;
					margin: 0.2em;
				}
				.time {
					font-size: 2.188rem;
					font-weight: 700;
					margin: 0.2em;
					color: var(--white-color);
				}
			}
			.label {
				font-weight: 600;
				font-size: 20px;
				line-height: 27px;
				text-align: center;

				color: #ffecec;
			}
		}
		.call_controls {
			.flex_row_controls {
				display: grid;
				grid-template-columns: 1fr 1fr 1fr;
				grid-row-gap: 1rem;
				.call_control_button {
					height: 4rem;
					width: 4rem;
					padding-bottom: 5.5rem;
					background-color: transparent;
					border: transparent;
					color: #ffffff;
					margin: auto;
					position: relative;
					cursor: pointer;

					&:active {
						opacity: 0.9;
					}
					&:hover {
						opacity: 0.5;
					}
					.button_inner_wrap {
						.call_control_button_graphic {
						}
						.call_control_button_label {
							text-align: center;
							position: absolute;
							transform: translateX(-50%);
							left: 50%;
							width: max-content;
							padding-top: 0.4rem;
							font-weight: 700;
							font-size: 16px;
							line-height: 20px;
							color: #ffffff;
						}
					}
				}
			}
		}
	}
	.container {
		.popup {
			background: #fff;
			border-radius: 3px;
			transform: translate(-50%, -50%);
			top: 50%;
			left: 50%;
			box-sizing: border-box;
			padding: 1.5em 1rem;
			position: absolute;
			max-width: 30rem;
			width: calc(100% - 2rem);
			.heading {
				h4 {
					font-family: "Manrope";
					font-style: normal;
					font-weight: 800;
					font-size: 18px;
					line-height: 25px;
					text-align: center;
					color: #212026;
					margin: 0.2em;
				}
			}
			.summary {
				p {
					font-family: "Manrope";
					font-style: normal;
					font-weight: 600;
					font-size: 16px;
					line-height: 22px;
					text-align: center;
					color: #464646;
					margin-bottom: 1em;
				}
			}
			.controls {
				button {
					font-family: "Manrope";
					font-style: normal;
					font-weight: 800;
					font-size: 16px;
					line-height: 2.8em;
					text-transform: capitalize;
					color: #ffffff;
					background: #f19b3e;
					border-radius: 3px;
					width: 100%;
					border: none;
					margin: 0.5rem 0;
					&.cancel {
						background: #f1f1f1;
						color: var(--main-orange-color);
					}
				}
			}
		}
	}
}
