.login_page {
	padding: 1em;
	max-width: 30rem;
	margin: auto;
	.logo {
		width: 125px;
		margin: 3em auto;
	}
	.form {
		h1 {
			font-size: 21px;
			margin: 0.3em 0;
		}
		p {
			font-size: 1rem;
			margin: 0.3em 0;
		}
		.error {
			font-size: 0.8em;
			text-align: center;
			color: var(--red-color);
		}
		.form_fields {
			padding: 0.5em 0em 1em;
			display: grid;
			grid-gap: 1em;
			.input {
				display: grid;
				grid-gap: 0.5em;
				label {
					font-size: 12px;
					line-height: 15px;
					font-weight: 800;
					text-transform: uppercase;
					color: var(--light-grey-color);
				}
				input {
					background: #f9f9f9;
					outline: 1px solid #ececec;
					color: var(--dark-color);
					line-height: 1.5;
					font-size: 1rem;
					padding: 0.4em 1em 0.6em;
					font-weight: 600;
					top: 1em;
					left: 1em;
					width: 100%;
					border: none;
					border-radius: 3px;
					box-sizing: border-box;
					&.input_error {
						outline: 1px solid var(--peach-color) !important;
					}
				}
				.phone_input {
					height: 2.5em;
					display: grid;
					grid-template-columns: 4.7em 1fr;
					select {
						background: var(--main-orange-color);
						outline: 1px solid #da8849;
						color: var(--white-color);
						border: none;
						border-radius: 3px 0px 0px 3px;
						box-sizing: border-box;
						z-index: 2;
						width: 4.7em;
						height: 2.5em;
						padding: 0 0.5em;
						font-weight: 600;
						&.input_error {
							outline: 1px solid var(--peach-color) !important;
						}
					}
					input {
						background: #f9f9f9;
						outline: 1px solid #ececec;
						line-height: 1.5;
						font-size: 1rem;
						padding: 0.4em 0.5em 0.6em;
						font-weight: 600;
						width: calc(100% - 0em);
						border: none;
						border-radius: 0px 3px 3px 0px;
						box-sizing: border-box;
					}
				}
			}
		}
		.controls {
			margin: 1em 0 2em;
		}
	}
}
