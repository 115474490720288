.standard_button,
.loading_button {
	color: var(--white-color);
	background-color: var(--main-orange-color);
	font-size: 1rem;
	font-weight: 600;
	line-height: 1.5;
	padding: 0.4em 1em 0.6em;
	width: 100%;
	border-radius: 3px;
	border: none;
	cursor: pointer;
	display: grid;
	&:disabled {
		opacity: 0.7;
	}
	&:hover {
		opacity: 0.9;
	}
	&:active {
		opacity: 1;
		background-color: var(--lighter-grey-color);
		color: var(--main-orange-color);
	}
	.loader {
		margin: auto;
		height: 30px;
		width: 24px;
		rect {
			fill: var(--main-orange-color);
		}
	}
}
.standard_button {
	background-color: var(--main-orange-color);
}
.loading_button {
	background-color: var(--white-color);
}
