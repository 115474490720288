@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
	.text-balance {
		text-wrap: balance;
	}
}

body {
	font-family: "DM Sans", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-style: normal;
	font-weight: 500;
	background-color: #f6f7ff;
	min-height: 100dvh;
}
